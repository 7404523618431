.home{
    position: relative;
    padding-top: 63px;
}

#hero-section {
    position: relative;
}
  
  /* Video Background */
#myVideo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
}

.overlay {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.section-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    color: white;
}
  
@media (min-width: 1024px) {
    .section-container {
      min-height: 800px;
    }
}
  
  /* Content */

.services-about{
    padding: 6rem;
    font-family: "Montserrat", sans-serif;
    text-align: center;
    @media (max-width: 1024px) {
      padding: 2rem;
      p{
        font-size: 17px;
      }
    }
    h1{
        text-align: center;
        font-size: 32px;
        font-weight: 550;
        padding-bottom: 0.1rem;
    }
    p{
        text-align: center;
        color: #747474;
        font-size: 17px;
        line-height: 1.7;
        @media (max-width: 1024px) {
              font-size: 15px;
          }
    }
    .btnn {
        display: inline-block;
        padding: 1rem 1.5rem;
        border: 1px solid black;
        color: #fff;
        background-color: #000;
        text-decoration: none;
        transition: background-color 0.3s;
        font-family: "Montserrat", sans-serif;
        margin-top: 2rem;
        @media (max-width: 1024px) {
            font-size: 15px;
        }
    }
      
    .btnn:hover {
        background-color: #fff;
        border: #000 1px solid;
        color: #000;
    }
}
.section-content {
    max-width: 550px;
    padding-top: 0px;
    padding-bottom: 84px;
    padding-left: 140px;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }

    h1{
        font-size: 60px;
        font-weight: 400;
        font-family: "Playfair Display", serif;
        text-transform: uppercase;
        line-height: 0.9;
    }
    p{
        font-family: "Montserrat", sans-serif;
        padding-bottom: 10px;
    }
    @media screen and (max-width: 500px) {
        padding: 1rem;
        margin-left: auto;
        margin-right: auto;

        h1{
            font-size: 50px;
        }
    }
}
  
  /* Description */
.description {
    margin: 1.5rem 0;
}
  
  /* Button */
.btn {
    display: inline-block;
    padding: 1rem 1.5rem;
    border: 1px solid white;
    color: #fff;
    text-decoration: none;
    transition: background-color 0.3s;
    font-family: "Open Sans", sans-serif;
}
  
.btn:hover {
    background-color: #000;
    border: #000;
}

.services-section{
    width: 100%;
    padding: 2rem 0;
    background-color: #000;
}

.service-1{
    display: flex;
    width: 100%;
    min-height: 75vh;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;

    @media screen and (max-width: 500px) {
        min-height: 15vh;
        margin-top: 0;
    }
    

    .left-1{
        flex: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: right;
        .left-container{
            background-color: #f1f5f9;
            padding-left: 10rem;
            min-height: 62vh;
            width: 60%;
            .left-image{
                img{
                    width: 33rem;
                    height: 21.5rem;
                    margin-top: 50px;
                    object-fit: cover;
                }
            }
    
        }
        @media screen and (max-width: 900px) {
            display: none;
        }
    }
    
    .right-1{
        flex: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center; /* Center vertically */
        align-items: left; /* Center horizontally */
        padding: 0 55px;
    
        @media screen and (max-width: 1000px) {
            padding: 40px 20px 20px;
        }
    
        h1{
            font-family: "Playfair Display", serif;
            text-transform: uppercase;
            font-size: 35px;
            font-weight: 450;
            @media screen and (max-width: 1000px) {
                padding: 0rem 1rem;
            }
        }
    
        p{
            margin-top: -20px;
            font-family: "Montserrat", sans-serif;
            font-size: 15px;
            font-weight: 400;
            padding-right: 5rem;
            line-height: 1.4;
            @media screen and (max-width: 1000px) {
                padding: 1rem 1rem;
                font-weight: 300;
            }
        }
    }
    
}

.service-2{
    display: flex;
    width: 100%;
    min-height: 75vh;
    background-color: #fff;
    @media screen and (max-width: 900px) {
        display: block;
        min-height: 20vh;
    }

    .left-2{
        flex: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center; /* Center vertically */
        align-items: left; /* Center horizontally */
        padding-right: 10rem;
        padding-left: 55px;
    
        @media screen and (max-width: 1000px) {
            padding: 10px 30px 35px;
            background-color: #f1f5f9;
        }
    
        h1{
            font-family: "Playfair Display", serif;
            text-transform: uppercase;
            font-size: 35px;
            font-weight: 450;
            @media screen and (max-width: 1000px) {
                padding: 0rem 1rem;
            }
        }
    
        p{
            margin-top: -20px;
            font-family: "Montserrat", sans-serif;
            font-size: 15px;
            font-weight: 400;
            padding-right: 5rem;
            line-height: 1.4;
            @media screen and (max-width: 1000px) {
                padding: 1rem 1rem;
                font-weight: 300;
            }
        }
    }

    .right-2{
        flex: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: right;
        @media screen and (max-width: 1000px) {
            justify-content: center;
            text-align: center;
        }
        .right-container{
            background-color: #f1f5f9;
            padding-right: 10rem;
            min-height: 62vh;
            display: flex;
            text-align: right;
            @media screen and (max-width: 1000px) {
                display: none;
                padding: 0;
                min-height: 0vh;
                text-align: center;
                background-color: #fff;
            }
            img{
                width: 33rem;
                height: 21.5rem;
                margin-top: 50px;
                margin-left: -8rem;
                object-fit: cover;
                @media screen and (max-width: 1000px) {
                    margin: 0;
                    width: 100%;
                }
            }
    
        }
    }
}

.random-img{
    display: none;
    @media screen and (max-width: 1000px) {
        display: block;
        img{
            height: 20rem;
            width: 100%;
            object-fit: cover;
        }
    }
}

.service-3{
    display: flex;
    width: 100%;
    min-height: 75vh;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    @media screen and (max-width: 1000px) {
        min-height: 20vh;
    }

    .left-3{
        flex: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: right;
        .left-container{
            background-color: #f1f5f9;
            padding-left: 10rem;
            min-height: 62vh;
            width: 60%;
            @media screen and (max-width: 1000px) {
                min-height: 15vh;
            }
            img{
                width: 33rem;
                height: 21.5rem;
                margin-top: 50px;
                object-fit: cover;
            }
    
        }
        @media screen and (max-width: 1000px) {
            display: none;
        }
    }
    
    .right-3{
        flex: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center; /* Center vertically */
        align-items: left; /* Center horizontally */
        padding: 0 55px;
    
        @media screen and (max-width: 1000px) {
            display: block;
            padding: 40px 20px 20px;
        }
    
        h1{
            font-family: "Playfair Display", serif;
            text-transform: uppercase;
            font-size: 35px;
            font-weight: 450;
            @media screen and (max-width: 1000px) {
                padding: 0rem 1rem;
            }
        }
    
        p{
            margin-top: -20px;
            font-family: "Open Sans", sans-serif;
            font-size: 15px;
            font-weight: 400;
            padding-right: 5rem;
            line-height: 1.4;
            @media screen and (max-width: 1000px) {
                padding: 1rem 1rem;
                font-weight: 300;
            }
        }
    }
    
}

.segment-backgroung{
    width: 100%;
    height: 60vh; /* Full viewport height */
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://res.cloudinary.com/dsnontai6/image/upload/v1706921474/pexels-rachel-claire-4992771_qj7yyv.jpg');
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the image */
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    text-align: center;
    
    .segment-content{
        color: #fff;
        padding: 0 24rem;
        @media screen and (max-width: 1000px) {
            padding: 1rem 1rem;
        }
        h2{
            font-family: "Playfair Display", serif;
            text-transform: uppercase;
            font-size: 35px;
            font-weight: 450;
            @media screen and (max-width: 500px) {
                font-size: 25px;
            }
        }
    
        p{
            font-family: "Montserrat", sans-serif;
            font-size: 15px;
            font-weight: 400;
            padding-right: 5rem;
            line-height: 1.7;
            padding-bottom: 1rem;
            @media screen and (max-width: 1000px) {
                padding: 1rem 0rem;
            }
        }
    }
}

.instagram-section{
    background-color: #f1f5f9;
    height: 90vh;
    margin-top: -33px;
    @media screen and (max-width: 500px) {
        height: 50vh;
        padding: 0 0 5rem;
    }
    @media screen and (min-width: 800px) {
        height: 80vh;
        padding: 0 0 5rem;
    }
    h1{
        text-transform: uppercase;
        font-family: "Playfair Display", serif;
        text-align: center;
        font-weight: 400;
        font-size: 35px;
        padding-top: 3.5rem;
        padding-bottom: 2rem;
        @media screen and (max-width: 500px) {
            font-size: 25px;
          }
    }
    .instagram-content{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center; /* Center content vertically */
        align-items: center; /* Center content horizontally */
        text-align: center;
    }
    .instagram-grid{
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        grid-auto-rows: auto;
        column-gap: 10px;
        row-gap: 7px;
        background-color: rgba(0, 0, 0, 0.5);
        @media screen and (max-width: 1000px) {
            column-gap: 1px;
            row-gap: 1px;
            grid-template-columns: repeat(6, 1fr);
        }
        
        img {
            width: 100%;
            height: auto;
            filter: brightness(50%);
            display: block;
          }
    }
    .instagram-button{
        position: absolute;
    }
    .instagram-btn{
        display: inline-block;
        padding: 1rem 1.5rem;
        background-color: #fff;
        border: 1px white solid;
        text-decoration: none;
        color: #000;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
    }
}
