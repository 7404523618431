.app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 10rem;
    background: white;
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    position: fixed;
    z-index: 4;
    height: 12vh;

    @media screen and (max-width: 1000px) {
      padding: 1rem 0rem;
    }
  }
  
  .app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    a{
      text-decoration: none;
      color: black;
    }
    // h1{
    //   font-size: 20px;
    // }
    img {
      width: 200px;
      height: 70px;
      margin-left: 0px;
  
      @media screen and (min-width: 2000px) {
        width: 180px;
        height: 40px;
      }
    }
    @media screen and (max-width: 1000px) {
      h1{
        font-size: 17px;
        padding-left: 2rem;
      }
      img{
        width: 170px;
        height: 60px;
        margin-left: 25px;
      }
    }
  }
  
  .app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    font-family: "Montserrat", sans-serif;

    .active::after{
      content: '';
      display: block;
      margin: 0px 0 0 13px ;
      width: 30%;
      height: 1px;
      background: black;
      transition: width 0.3s;
    }
  
    a:link { text-decoration: none; }

    li {
      margin: 0 0.8rem;
      cursor: pointer;
      flex-direction: column;
      color: black;
      font-size: 15px;
      text-transform: capitalize;
      font-weight: 400;
      padding-bottom: 2px;
  
      div {
        width: 5px;
        height: 5px;
        background: transparent;
        border-radius: 50%;
        margin-bottom: 5px;
      }
  
      a {
        color: var(--gray-color);
        text-decoration: none;
        flex-direction: column;
        font-family: "Open Sans", sans-serif;
        transition: all 0.3s ease-in-out;
  
        &:hover {
          color: white;
        }
      }
  
      // &:hover {
      //   div {
      //     background: var(--secondary-color);
      //   }
      // }
    }
  
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
  
  .app__navbar-menu {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    padding-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  
  
    svg {
      width: 90%;
      height: 90%;
      color: var(--gray-color);
    }
  
    div {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 5;
      padding: 0rem;
      padding-top: 3rem;
      margin-top: -1px;
      width: 100.3%;
      height: 100vh;
      
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      color: #fff;
      background: linear-gradient(90deg, #000 0%, #000 100%);
      background-color: #000;
      background-size: cover;
      background-repeat: repeat;
  
      /* top box shadow */
      box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);
  
      svg {
        width: 35px;
        height: 35px;
        color: var(--secondary-color);
        margin: 0rem 1rem;
      }
  
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
  
        li {
          margin: 1rem;
  
          a {
            color: var(--gray-color);
            text-decoration: none;
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: 500;
            transition: all 0.3s ease-in-out;
  
            &:hover {
              color: var(--secondary-color);
            }
          }
        }
      }
  
      @media screen and (min-width: 900px) {
        display: none;
      }
    }
  
    @media screen and (min-width: 900px) {
      display: none;
    }
  }

  
  .dropdown {
    float: left;
    overflow: hidden;
  }

  .dropdown .dropbtn {
    font-size: 15px;
    border: none;
    outline: none;
    color: black;
    background-color: inherit;
    font-family: inherit; /* Important for vertical align on mobile phones */
    padding-bottom: 4px;
    display: flex;
    align-items: center;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  .dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown-btn{
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 20px;
    color: #818181;
    display: block;
    border: none;
    background: none;
    width:100%;
    text-align: left;
    cursor: pointer;
    outline: none;
  }

  .drop-btn{
      text-transform: uppercase;
      color: white;
      background-color: #000;
      outline: none;
      padding: 0;
      margin: 0;
      font-size: 1rem;
      font-weight: 500;
      transition: all 0.3s ease-in-out;
      display: flex;
      align-items: center;
      text-align: left;
      border: none;
  }
