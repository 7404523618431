.portfolio{
    .wedding{
        background-color: #000;
        margin: -33px 0 24px;
        padding: 5rem 4rem;
        @media screen and (max-width: 500px){
            padding: 5rem 1rem;
        }
      }
    .album-header_text{
        text-align: left;
        padding: 0;
        color: #fff;
        padding-top: 1rem;
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://res.cloudinary.com/dsnontai6/image/upload/v1719359798/pexels-thiago-jose-amaral-3362454-6274899_dhcbrh.jpg');
        // filter: grayscale(100%);
        h1{
            text-transform: capitalize;
            text-align: left;
            padding: 13rem 0rem 13rem 10rem;
            font-family: "Montserrat", sans-serif;
            font-weight: 300;
            line-height: 2;
            font-size: 45px;
            @media screen and (max-width: 500px){
                padding: 10rem 1rem;
                font-size: 35px;
            }
        }
    }
    position: relative;
    padding-top: 63px;
    background-color: #fff;
    padding-bottom: 2rem;
    min-height: 100vh;
    h1{
        text-transform: uppercase;
        font-family: "Montserrat", sans-serif;
        text-align: center;
        font-weight: 400;
        font-size: 35px;
        padding-top: 1rem;
    }
    a{
        text-decoration: none;
    }
}

.portfolio-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;

    .portfolio-container_item{
        width: 380px;
        flex-direction: column;
        min-height: 550px;
        margin: 1rem;
        background-size: cover;
        background-position: center;
        text-align: center;
        text-transform: capitalize;
        font-family: "Montserrat", sans-serif;
        display: flex;
        justify-content: center;
        font-size: 23px;
        
        position: relative;
        h2{
            z-index: 3;
            position: relative;
            font-weight: 550;
            color: #000;
            padding: 14rem 0;
        }
    }
    .portfolio-container_item::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(136, 136, 136, 0.5); /* Optional: darken the blurred background */
        z-index: 0; 
      }
}

// .portfolio-container_item{
//     position: relative;
//     width: 380px;
//     height: 500px;
//     margin: 1rem;
//     background-size: cover;
//     background-position: center;
//     text-align: center;
//     text-transform: capitalize;
//     font-family: "Open Sans", sans-serif;
//     font-size: 22px;
//     color: black;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     overflow: hidden;
//     h2{
//         position: relative;
//         z-index: 3;
//         margin: 0;
//         padding: 0;
//         text-decoration: none;
//         color: #000;
//     }
// }




  .portfolio2{
    position: relative;
    padding-top: 75px;
    padding-bottom: 2rem;
    width: 90%;
    margin: 0 auto;
    height: 100%;
    @media screen and (max-width: 500px) {
        padding-top: 70px;
    }

    .first-link{
        text-decoration: none;
        font-family: "Montserrat", sans-serif;
        a{
            text-decoration: none;
            color: #000;
            font-size: 17px;
            p{
                display: flex;
                align-items: center;
                font-family: "Montserrat", sans-serif;
            }
        }
    }

    .portfolio2-container{
        display: flex;
        width: 100%;
        min-height: 30vh;
        margin-left: auto;
        margin-right: auto;
        @media screen and (max-width: 500px) {
            display: block;
        }
    }
    .left{
        flex: 40%;
        padding: 50px 100px 50px;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        
        @media screen and (max-width: 500px) {
            padding: 10px 20px;
        }
    
        h1{
            font-family: "Playfair Display", serif;
            font-weight: 600;
            font-size: 50px;
            text-align: center;
        }
    
        p{
            text-align: center;
            font-family: "Montserrat", sans-serif;
            font-size: 35px;
        }
      }
    
      .right{
        flex: 50%;

        .right-image{
            height: 70vh;
        }
        
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        @media screen and (max-width: 500px) {
            
        }
    }

    .bottom{
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding: 2rem 5rem 0;
        font-family: "Montserrat", sans-serif;
        font-size: 20px;
        @media screen and (max-width: 500px) {
            display: block;
            padding: 0;
            text-align: left;
            font-size: 17px;
        }
        p{
            font-weight: 300;
        }

        .bottom-link{
            text-decoration: none;
            color: #000;
            @media screen and (max-width: 500px) {
                text-align: center;
                justify-content: center;
                align-items: center;
            }
            a{
                text-decoration: none;
                color: #000;
                font-weight: 3;
                display: flex;
                align-items: center;

                &:hover{
                    color: #FF5733;
                }
            }
        }
    }
    
  }

  .portfolio3{
    position: relative;
    padding: 80px 2rem 2rem;
    min-height: 50vh;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    @media screen and (max-width: 500px) {
        padding: 70px 2rem 2rem;
    }
.image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
    }
    .first-link{
        text-decoration: none;
        padding-left: 5rem;
        @media screen and (max-width: 500px) {
            padding: 0;
        }
        
        h2{
            text-align: center;
            font-family: "Montserrat", sans-serif;
            font-weight: 500;
            font-size: 25px;
            padding-bottom: 2rem;
        }
        a{
            text-decoration: none;
            color: #000;
            font-size: 17px;
            p{
                display: flex;
                align-items: center;
            }
        }
    }
    img{
        object-fit: cover;
        margin: 1rem;
        
    }
  }
  

  .loader{
    text-align: center;
    margin-top: 5rem;
  }

  
  
