.footer{
    height: 20vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 0 15rem;

    p{
        font-family: "Montserrat", sans-serif;
        font-weight: 450;
        text-decoration: none;
        color: black;
        a{
            text-decoration: none;
            color: black;
        }
    }

    .footer-links{
        a{
            text-decoration: none;
            color: black;
            margin-left: 15px;
        }
    }
    @media screen and (max-width: 500px) {
        display: block;
        height: 15vh;
        padding: 1rem 1rem;
        p{
            font-size: 14px;
        }
      }
}
