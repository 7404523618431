
.service{

    .service-header_text{
        text-align: left;
        padding: 0;
        color: #fff;
        padding-top: 1rem;
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://res.cloudinary.com/dsnontai6/image/upload/v1706921473/pexels-brett-sayles-2388569_pd4gqw.jpg');
        h1{
            text-transform: capitalize;
            text-align: left;
            padding: 13rem 0rem 13rem 10rem;
            font-family: "Montserrat", sans-serif;
            font-weight: 300;
            line-height: 2;
            font-size: 45px;
            @media screen and (max-width: 500px){
                padding: 10rem 1rem;
                font-size: 35px;
            }
        }
        p{
            padding: 3rem 6rem 0;
            font-size: 18px;
            line-height: 1.6;
            text-transform: lowercase;
            @media screen and (max-width: 500px) {
                padding: 40px 20px 20px;
                font-size: 16px;
                text-align: justify;
            }
            @media screen and (min-width: 800px) {
                padding: 40px 20px 20px;
                font-size: 21px;
                text-align: justify;
            }
            
        }
    }
    position: relative;
    padding-top: 63px;
    background-color: rgb(251, 251, 251);
    padding-bottom: 2rem;
    h1{
        text-transform: uppercase;
        font-family: "Montserrat", sans-serif;
        text-align: center;
        font-weight: 400;
        font-size: 35px;
        padding-top: 1rem;
    }
    h2{
        text-transform: uppercase;
        font-family: "Montserrat", sans-serif;
        font-size: 26px;
        font-weight: 500;
        text-align: center;
        color: #FF5733;
        @media screen and (max-width: 500px) {
            font-size: 25px;
        }
    }
    .service-content{
        padding: 1rem 10rem;
        p{
            font-family: "Montserrat", sans-serif;
            font-weight: 500;
            line-height: 1.6;
        }
    }
    .wedding{
        padding-top: 3rem;
        .wed-head{
            width: 100%;
            margin: 0 auto;
            text-align: center;
            @media screen and (max-width: 500px) {
                width: 15rem;
                border-bottom: none;
            }
        }
    }
}
.service-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding-top: 0rem;
        @media screen and (max-width: 1000px) {
            padding: 0;
        }

    .service-container-item{
        max-width: 420px;
        flex-direction: column;
        min-height: 850px;
        margin: 1rem;
        background-color: #fff;
        border: #000 1px solid;
        color: #000;
        cursor: pointer;
        transition: all 0.3s ease;

        @media screen and (max-width: 1000px) {
            min-height: 900px;
        }
    
        // &:hover {
        //     box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
        // }
    
        @media screen and (min-width: 2000px) {
            width: 470px;
            padding: 1.25rem;
            border-radius: 0.75rem;
        }
    
        @media screen and (max-width: 300px) {
            width: 100%;
            margin: 1rem;
        }
    }
}

.service-image {
    width: 100%;
    height: 230px;
    text-align: center;
    position: relative;
  
    img {
      width: 85%;
      height: 100%;
      // border-radius: 0.5rem;
      object-fit: cover;
    }
    @media screen and (min-width: 2000px) {
        height: 350px;
      }
}

.service-title{
    text-align: center;
    font-family: "Cactus Classical Serif", serif;
    text-transform: uppercase;
    h3{
        font-weight: 400;
    }
}
.service-hour{
    font-family: "Montserrat", sans-serif;
    text-align: center;
    h3{
        font-weight: 400;
    }   
}
.service-desc{
    font-family: "Montserrat", sans-serif;
    padding: 0.2rem 2rem;
    p{
        font-weight: 350;
        font-size: 15px;
        line-height: 1.4;
    }
}

ul, ol {
    margin: 0;
    padding: 0;
}

.service-dets{
    font-family: "Montserrat", sans-serif;
    font-weight: 350;
    padding: 0 2rem;
    font-size: 15px;
    line-height: 1.4;
    padding-bottom: 2rem;
    li{
        list-style-type: square;
        padding-bottom: 10px;
        font-size: 16px;
        line-height: 1.4;
    }
}

.service-price{
    font-family: "Montserrat", sans-serif;
    padding: 0 2rem;
    border-top: 1px solid black;
    p{
        font-size: 25px;
        font-weight: 400;
    }
}

.service-button{
    text-align: center;
    a{
        text-decoration: none;
        background-color: #000;
        color: #fff;
        border: 1px solid black;
        padding: 0.8rem 2rem;
        border-radius: 5px;
    }
    a:hover{
        background-color: #fff;
        color: #000;
    }
}

.shoot-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 0rem;

.shoot-container-item{
    max-width: 420px;
    flex-direction: column;
    min-height: 670px;
    margin: 1rem;
    background-color: #fff;
    border: #000 1px solid;
    color: #000;

    cursor: pointer;
    transition: all 0.3s ease;

    // &:hover {
    //     box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    // }

    @media screen and (min-width: 2000px) {
        width: 470px;
        padding: 1.25rem;
        border-radius: 0.75rem;
    }

    @media screen and (max-width: 300px) {
        width: 100%;
        margin: 1rem;
    }
}
}
