:root {
  --white: #afafaf;
  --red: #e31b23;
  --bodyColor: #292a2b;
  --borderFormEls: hsl(0, 0%, 10%);
  --bgFormEls: hsl(0, 0%, 14%);
  --bgFormElsFocus: hsl(0, 7%, 10%);
}

.contact{
    position: relative;
    padding-top: 63px;
    background-color: #000;
    min-height: 100vh;
    *{
        box-sizing: border-box;
    }
    @media screen and (max-width: 500px) {
      line-height: 1.7;
  }
}
.contact-row{
  text-align: left;
        padding: 0;
        color: #fff;
        padding-top: 1rem;
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://res.cloudinary.com/dsnontai6/image/upload/v1672493639/comfort/pexels-photo-821754_omrcok.jpg');
        // filter: grayscale(100%);
        h1{
            text-transform: capitalize;
            text-align: left;
            padding: 15rem 0rem 15rem 10rem;
            font-family: "Montserrat", sans-serif;
            font-weight: 300;
            line-height: 2;
            font-size: 45px;
            @media screen and (max-width: 500px){
              padding: 10rem 1rem; font-weight: 300;
              font-size: 35px
          }
        }
        p{
            padding: 3rem 6rem 0;
            font-size: 18px;
            line-height: 1.6;
            text-transform: lowercase;
            @media screen and (max-width: 500px) {
                padding: 40px 20px 20px;
                font-size: 16px;
                text-align: justify;
            }
            @media screen and (min-width: 800px) {
                padding: 40px 20px 20px;
                font-size: 21px;
                text-align: justify;
            }
            
        }
}
.contact-container{
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    font-family: "Montserrat", sans-serif;
    color: #fff;

    h4{
      text-align: center;
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      font-size: 17px;
    }
    ul {
        list-style: none;
        padding: 0;
      }
  .dets{
        text-align: center;
        padding: 2rem 0;
      }
}
 
.brand {
    text-align: center;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 0.1em;
}

.brand span {
    color: #ffffff;
}
  
.wrapper {
    // box-shadow: 0 0 20px 0 rgba(57, 82, 163, 0.7);
    min-height: 30vh;
    border-radius: 20px;
}

.wrapper > * {
    padding: 1em;
    margin: 3rem 0 6rem;
    @media screen and (max-width: 500px){
      margin: 0;
  }
}

.company-image{
    text-align: center;
    padding-bottom: 3rem;
    padding-top: 2rem;
    img{
        width: 180px;
        height: 60px;
    }
}
.company-info {
    background-color: #F5F5F5;
    color: black;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  
.company-info h3,
.company-info ul {
    text-align: center;
    margin: 0 0 1rem 0;
}

.contact-us {
    backdrop-filter: blur(4px);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: rgb(255, 255, 255);

    span{
      color: red;
    }
}

#contact-form{
  padding: 2rem 0;
}

.contact-us form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  
  .contact-us form label {
    display: block;
    color: #000;
  }
  
  .contact-us form p {
    margin: 0;
  }
  
  .contact-us form .full {
    grid-column: 1 / 3;
  }
  
  .contact-us form button,
  .contact-us form input,
  .contact-us form textarea {
    width: 100%;
    padding: 0.8em;
    border: solid 1px #000;
    border-radius: 4px;
    font-size: 14px;
    background-color: #f8f8f8;
    @media screen and (max-width: 500px){
      padding: 0.6em;
  }
  }

  .in-date{
    height: 2.9em;
    @media screen and (max-width: 500px){
      height: 2.5em;
  }
  }

  .contact-us form select
  {
    width: 100%;
    padding: 1em;
    border: solid 1px #000;
    border-radius: 4px;
    font-size: 14px;
    height: 2.9em;
    resize: none;
    color: #000;
    @media screen and (max-width: 500px){
      height: 2.6em;
  }
  }
  
  .contact-us form textarea {
    resize: none;
  }
  
  .contact-us form button {
    background: #000;
    border: 0;
    color: #fff;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
  }
  
  .contact-us form button:hover,
  .contact-us form button:focus {
    background: #fff;
    color: #000;
    border: #000 solid 1px;
    outline: 0;
    transition: background-color 1s ease-out;
    cursor: pointer;
  }

  @media only screen and (min-width: 1000px) {
    .wrapper {
      display: grid;
      grid-template-columns: 1fr 2fr;
    }
  
    .wrapper > * {
      padding: 2em;
    }
  
    .company-info {
      border-radius: 10px 0 0 10px;
    }
  
    .contact-us {
      border-radius: 0 10px 10px 0;
    }
  
    .company-info h3,
    .company-info ul,
    .brand {
      text-align: left;
      line-height: 1.9;
      font-family: "Montserrat", sans-serif;
      font-weight: 300;
    }
    
  }

  .my-form h1 {
    margin-bottom: 1.5rem;
  }
  
  .my-form li,
  .my-form .grid > *:not(:last-child) {
    margin-bottom: 1.5rem;
  }
  
  .my-form select,
  .my-form input,
  .my-form textarea,
  .my-form button {
    width: 100%;
    line-height: 1.5;
    padding: 15px 10px;
    border: 1px solid var(--borderFormEls);
    color: var(--white);
    background: var(--bgFormEls);
    transition: background-color 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25),
      transform 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25);
  }
  
  .my-form textarea {
    height: 170px;
  }
  
  .my-form ::placeholder {
    color: inherit;
    /*Fix opacity issue on Firefox*/
    opacity: 1;
  }
  
  .my-form select:focus,
  .my-form input:focus,
  .my-form textarea:focus,
  .my-form button:enabled:hover,
  .my-form button:focus,
  .my-form input[type="checkbox"]:focus + label {
    background: var(--bgFormElsFocus);
  }
  
  .my-form select:focus,
  .my-form input:focus,
  .my-form textarea:focus {
    transform: scale(1.02);
  }
  
  .my-form *:required,
  .my-form select {
    background-repeat: no-repeat;
    background-position: center right 12px;
    background-size: 15px 15px;
  }
  
  .my-form *:required {
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/asterisk.svg);  
  }
  
  .my-form select {
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/down.svg);
  }
  
  .my-form *:disabled {
    cursor: default;
    filter: blur(2px);
  }

  .my-form .required-msg {
    display: none;
    background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/asterisk.svg)
      no-repeat center left / 15px 15px;
    padding-left: 20px;
  }
  
  .my-form .btn-grid {
    position: relative;
    overflow: hidden;
    transition: filter 0.2s;
  }
  
  .my-form button {
    font-weight: bold;
  }
  
  .my-form button > * {
    display: inline-block;
    width: 100%;
    transition: transform 0.4s ease-in-out;
  }
  
  .my-form button .back {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-110%, -50%);
  }
  
  .my-form button:enabled:hover .back,
  .my-form button:focus .back {
    transform: translate(-50%, -50%);
  }
  
  .my-form button:enabled:hover .front,
  .my-form button:focus .front {
    transform: translateX(110%);
  }
  
  
  /* CUSTOM CHECKBOX
  –––––––––––––––––––––––––––––––––––––––––––––––––– */
  .my-form input[type="checkbox"] {
    position: absolute;
    left: -9999px;
  }
  
  .my-form input[type="checkbox"] + label {
    position: relative;
    display: inline-block;
    padding-left: 2rem;
    transition: background 0.3s cubic-bezier(0.57, 0.21, 0.69, 1.25);
  }
  
  .my-form input[type="checkbox"] + label::before,
  .my-form input[type="checkbox"] + label::after {
    content: '';
    position: absolute;
  }
  
  .my-form input[type="checkbox"] + label::before {
    left: 0;
    top: 6px;
    width: 18px;
    height: 18px;
    border: 2px solid var(--white);
  }
  
  .my-form input[type="checkbox"]:checked + label::before {
    background: var(--red);
  }
  
  .my-form input[type="checkbox"]:checked + label::after {
    left: 7px;
    top: 7px;
    width: 6px;
    height: 14px;
    border-bottom: 2px solid var(--white);
    border-right: 2px solid var(--white);
    transform: rotate(45deg);
  }

  .my-form{
    a {
      color: inherit;
    }
    
    input,
    select,
    textarea,
    button {
      font-family: inherit;
      font-size: 100%;
    }
    
    button,
    label {
      cursor: pointer;
    }
    
    select {
      appearance: none;
    }
    
    /* Remove native arrow on IE */
    select::-ms-expand {
      display: none;
    }
    
    /*Remove dotted outline from selected option on Firefox*/
    /*https://stackoverflow.com/questions/3773430/remove-outline-from-select-box-in-ff/18853002#18853002*/
    /*We use !important to override the color set for the select on line 99*/
    select:-moz-focusring {
      color: transparent !important;
      text-shadow: 0 0 0 var(--white);
    }
    
    textarea {
      resize: none;
    }
    
    ul {
      list-style: none;
    }
    
    body {
      font: 18px/1.5 "Open Sans", sans-serif;
      background: var(--bodyColor);
      color: var(--white);
      margin: 1.5rem 0;
    }
    
    .container {
      max-width: 800px;
      margin: 0 auto;
      padding: 0 1.5rem;
    }
  }

  @media screen and (min-width: 600px) {
    .my-form .grid {
      display: grid;
      grid-gap: 1.5rem;
    }
  
    .my-form .grid-2 {
      grid-template-columns: 1fr 1fr;
    }
  
    .my-form .grid-3 {
      grid-template-columns: auto auto auto;
      align-items: center;
    }
  
    .my-form .grid > *:not(:last-child) {
      margin-bottom: 0;
    }
  
    .my-form .required-msg {
      display: block;
    }
  }
  
  @media screen and (min-width: 541px) {
    .my-form input[type="checkbox"] + label::before {
      top: 50%;
      transform: translateY(-50%);
    }
  
    .my-form input[type="checkbox"]:checked + label::after {
      top: 3px;
    }
  }
