// .about{
//     position: relative;
//     padding-top: 63px;
//     scroll-behavior: smooth;
//     background-color: #f1f5f9;    

//     img{
//         width: 100%;
//         -o-object-fit: cover;
//         object-fit: cover;
//     }
// }

// .about-me{
//     padding-top: 10px;
//     padding-bottom: 50px;
// }

// .about-me .about-me-container {
//     position: relative;
//   }
//   @media (max-width: 960px) {
//     .about-me .about-container {
//       padding-bottom: 100px;
//     }
//   }
//   .about-me .about-container .about-title {
//     h1{
//       font-size: 35px;
//       font-weight: 400;
//       font-family: "Playfair Display", serif;
//       text-transform: uppercase;
//       text-align: center;
//     }
//     h2{
//       text-align: left;
//       text-transform: capitalize;
//       font-weight: 400;
//       font-family: "Open Sans", sans-serif;
//     }
//   }
//   @media (max-width: 500px) {
//     .about-me .about-container .about-title {
//       font-size: 30px;
//     }
//   }

// .about-container1 {
//     max-width: 1300px;
//     margin: 0px auto 0px auto;
//     padding: 0px 40px;
//   }
//   @media (min-width: 1200px) and (max-width: 1441px) {
//     .about-container1 {
//       max-width: 1250px;
//       padding: 0px 36px;
//     }
//   }
//   @media (max-width: 767px) {
//     .about-container1 {
//       padding: 0px 30px;
//     }
//   }
//   @media (max-width: 479px) {
//     .about-container1 {
//       padding: 0px 30px 0px 20px;
//     }
//   }

//   .about-flex-container {
//     margin-top: 25px;
//     margin-left: 0px;
//     left: 100px;
//     display: flex;
//     justify-content: space-between;
//   }
//   @media (max-width: 960px) {
//     .about-flex-container {
//       flex-direction: column;
//       justify-content: center;
//       align-items: center;
//       margin-left: 0px;
//       gap: 50px;
//     }
//   }
//   @media (max-width: 500px) {
//     .about-flex-container {
//       margin-top: -10px;
//     }
//   }

//   .about-flex-container .about-image {
//     position: relative;
//     width: 400px;
//     height: 400px;
//   }
//   @media (max-width: 500px) {
//     .about-flex-container .about-image {
//       width: 300px;
//       height: 300px;
//     }
//   }
//   .about-flex-container .about-image .back-div {
//     position: absolute;
//     bottom: 0;
//     z-index: -3;
//     background-color: #013747;
//     width: 80%;
//     height: 80%;
//   }

//   .about-flex-container .about-image .black-image {
//     z-index: -2;
//     position: absolute;
//     left: 10px;
//     bottom: 10px;
//     height: 100%;
//   }
//   .about-flex-container .about-image .black-image img {
//     height: 100%;
//   }

//   .about-flex-container .about-image .main-image {
//     width: 100%;
//     height: 100%;
//     overflow: hidden;
//     position: absolute;
//     // left: 25%;
//     // top: 5%;
//     transition: all 0.2s ease-out;
//   }

//   .about-flex-container .about-image .main-image img {
//     transform-origin: center center;
//     transform: scale(1);
//     -o-object-fit: cover;
//        object-fit: cover;
//     transition: all 0.2s ease-out;
//   }

//   .about-flex-container .about-content {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     gap: 50px;
//     flex: 0 0 50%;
//     // padding: 0 0 0 2rem;
//     // border-left: rgb(116, 116, 116) 1px solid;
//   }
//   @media (max-width: 960px) {
//     .about-flex-container .about-content {
//       flex-direction: row-reverse;
//     }
//   }
//   .about-flex-container .about-content .logo {
//     max-width: 200px;
//   }
//   .about-flex-container .about-content .logo img {
//     filter: drop-shadow(0 0 25px rgb(0, 0, 0));
//   }
//   @media (max-width: 500px) {
//     .about-flex-container .about-content .logo img {
//       transform: rotateZ(90deg);
//     }
//   }
//   .about-flex-container .about-content .text {
//     color: #000;
//     font-weight: 350;
//     font-size: 14px;
//     line-height: 1.9;
//     font-family: "Maven Pro", sans-serif;
//     p{padding-bottom: 15px;}
//   }
//   @media (max-width: 500px) {
//     .about-flex-container .about-content .text {
//       font-size: 16px;
//     }
//   }

//   .mail-button {
//     display: flex;
//     height: 80px;
//     width: 80px;
//     border-radius: 50%;
//     padding: 15px;
//     justify-content: center;
//     align-items: center;
//     transition: all 0.2s ease-out;
//   }
//   .mail-button a {
//     display: flex;
//   }
//   .mail-button img {
//     -o-object-fit: contain;
//        object-fit: contain;
//     transition: all 0.2s ease-out;
//   }
  
  
//   .mail-button2 {
//     background-color: #001925;
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     border: 10px solid #001925;
//   }
//   .mail-button2 img {
//     filter: invert(56%) sepia(42%) saturate(4795%) hue-rotate(360deg) brightness(103%) contrast(105%);
//   }
//   .mail-button2:hover {
//     background-color: #FF7A01;
//     border: 10px solid #FF7A01;
//   }
//   .mail-button2:hover img {
//     filter: unset;
//     transform: scale(1.5);
//   }
  
//   .portfolio-link {
    
//     display: flex;
//     justify-content: center;
//     flex-wrap: wrap;
//   }
//   .portfolio-link a{
//     text-decoration: none;
//     color: white;
  
//     padding: 10px;
//     margin: 10px;
//     border-radius: 5px;
//   }

.about{
  position: relative;
  padding-top: 62px;
  scroll-behavior: smooth;
  background-color: #fff;
  margin: 0;
  padding-bottom: 5rem;
}

.masthead {
  padding: 1em 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url("https://res.cloudinary.com/dsnontai6/image/upload/v1717435366/IMG_2884_mq0tyo.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.masthead-heading {
  font-size: 6em;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: -0.15em;
  // text-shadow: 1.5px 3px 0px #fff;
  color: #fff;
  margin-top: -0.2em;

}
.masthead-intro {
  font-size: 2em;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  color: #e5e5e5;
  margin-bottom: 2em;
  padding-top: 10em;
}

.intro,
.where-Im-from,
.more-about-me {
  max-width: 50em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6em;
  text-align: left;

  p{
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    line-height: 1.9;
    font-size: 15px;
  }
  h1{
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
  }
  .logo{
    text-align: center;
    padding-top: 4rem;
    img{
      max-width: 200px;
      filter: drop-shadow(0 0 25px rgb(0, 0, 0));
    }
  }
}

@media only screen and (max-width:500px){
  .masthead {
    padding: 3em 0;
  }
  .masthead-heading {
    font-size: 3em;
  }
  .intro,
  .where-Im-from,
  .more-about-me,
  .content-footer {
    font-size: 0.5em;
    padding: 0 2rem;
    h1{
      font-size: 20px;
    }
  }
}

@media screen and (min-width: 800px) {
  .intro,
  .where-Im-from,
  .more-about-me,
  .content-footer {
    font-size: 0.5em;
    padding: 0 1rem;
    h1{
      font-size: 20px;
    }
  }
}
